<template>
  <div>
    <DialogConfirmation
      tag="add"
      :width="900"
      :scrollable="true"
      :show="dialogVincularColaborador"
      :title="'Vincular Colaborador'"
      :desabilitarConfirmacao="itemSelecionado ? false : true"
      @close="fecharDialogVincularColaborador()"
      @confirm="vincularColaborador()"
    >
      <template v-slot:body>
        <div>
          <AutocompletePaginadoMain
            class="mt-5"
            tituloItem="nome"
            placeholder="Selecione um coloborador"
            label="Colaboradores"
            mensagemItemNaoEncontrado="Nenhum colaborador encontrado"
            :chips="true"
            :deletableChips="true"
            :outlined="true"
            :solo="true"
            :items="colaboradoresNaoVinculados"
            @update:value="onUpdateSelect($event)"
            @scrollDown="adicionarMaisColaboraderesNaoVinculados"
            @pesquisa="buscarColardoresNaoVinculadosPorNome($event)"
          />

          <div v-if="exibirFormularioColaborador">
            <div class="main">
              <v-row>
                <v-col offset-sm="12" md="12">
                  <TitleH2 class="mb-2 mx-3" title="Especializações"/>

                  <v-chip-group v-if="listaEspecializacao.length > 0" class="mx-3" center-active>
                    <v-chip class="chip-especializacao" v-for="item in listaEspecializacao"
                      :key="item.id" :disabled="true"
                    >
                      {{ item.descricao }}
                    </v-chip>
                  </v-chip-group>

                  <span v-else class="aviso-sem-especializacao mx-3">
                    Não há especialização associada a este colaborador.
                  </span>

                  <TitleH2 class="mt-3 mb-5 mx-3" title="Dados do Colaborador" />
                  <FormField :readonly="true" :fields="fields" />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </template>
    </DialogConfirmation>

    <DialogConfirmation
      tag="delete"
      :scrollable="true"
      :width="800"
      :show="dialogDesvincularColaborador"
      :title="'Desvincular Colaborador'"
      @close="fecharDialogDesvincularColaborador()"
      @confirm="desvincularColaborador()"
    >
      <template v-slot:body>
        <div class="aviso-dialog">
          <p>
            ESTA AÇÃO DESVINCULARÁ
            <span style="font-weight: bold">
              {{ colaboradorSelecionado && colaboradorSelecionado.nome || '' }}
            </span>
            {{ labelContextoDesvinculacao }}.
          </p>
          <p>DESEJA CONTINUAR?</p>
        </div>
      </template>
    </DialogConfirmation>

    <CrudTable
      :headers="headers"
      :items="colaboradores"
      :pagination="pagination"
      :loading="loading"
      @actualPage="page"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <ButtonMain
            text="Vincular Colaborador"
            :primary="true"
            @click.native="abrirdialogVincularColaboradorr()"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <td class="celula-tabela email">
          <span :title="item.email" >{{ item.email }}</span>
        </td>
      </template>

      <template v-slot:[`item.cpf`]="{ item }">
        <span style="white-space: nowrap;">
          {{ item.cpf | VMask('###.###.###-##') }}
        </span>
      </template>

      <template v-slot:[`item.statusDescricao`]="{ item }">
        <StatusChip
          :success="item.statusDescricao === 'ATIVO' ? true : false"
          :warning="item.statusDescricao === 'EM ANALISE' ? true : false"
          :error="item.statusDescricao === 'INATIVO' ? true : false"
        >
            <span> {{ item.statusDescricao }} </span>
        </StatusChip>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <TooltipMain top text="Desvincular Colaborador">
          <ButtonIcon
              :secondary="true"
              icon="close-circle"
              @click.native="abrirDialogDesvincularColaborador(item)"
            />
        </TooltipMain>
      </template>
    </CrudTable>

    <div v-if="contextoComponente !== 'area-matriz'" class="footer-pagina">
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn class="ml-3 mt-5" :to="rotaDeVolta">
          <v-icon small>mdi-arrow-left-thin </v-icon>
          Voltar
        </v-btn></v-toolbar
      >
    </div>
  </div>
</template>

<script>
import CrudTable from '../../components/molecules/tables/CrudTable'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import ButtonIcon from '@/components/atoms/buttons/ButtonIcon.vue'
import StatusChip from '@/components/atoms/StatusChip.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import AutocompletePaginadoMain from '../../components/atoms/fields/AutocompletePaginadoMain.vue'
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import TooltipMain from '../../components/atoms/TooltipMain.vue'
import colaboradorService from '@/services/ColaboradorService'

export default {
  components: {
    CrudTable,
    ButtonMain,
    ButtonIcon,
    DialogConfirmation,
    StatusChip,
    AutocompletePaginadoMain,
    TitleH2,
    FormField,
    TooltipMain
  },

  data() {
    return {
      itemSelecionado: '',
      contextoComponente: '',
      rotaDeVolta: '',
      labelContextoDesvinculacao: '',
      colaboradorSelecionado: null,
      colaboradoresNaoVinculados: [],
      exibirFormularioColaborador: false,
      loading: false,
      dialogVincularColaborador: false,
      dialogDesvincularColaborador: false,
      colaboradores: [],
      listaEspecializacao: [],
      fields: {
        nome: {
          label: 'Nome',
          valueField: null,
          value: '',
          cols: 7,
          readonly: true,
          field: 'InputField'
        },

        cpf: {
          label: 'CPF',
          valueField: null,
          value: '',
          cols: 5,
          readonly: true,
          field: 'InputMaskField',
          maskFormat: '###.###.###-##'
        },

        email: {
          label: 'Email',
          valueField: null,
          cols: 7,
          value: '',
          readonly: true,
          field: 'InputField',
        },

        ddd: {
          label: 'DDD',
          valueField: null,
          value: '',
          cols: 2,
          readonly: true,
          field: 'InputMaskField',
          maskFormat: '##'
        },

        telefone: {
          label: 'Telefone',
          valueField: null,
          value: '',
          cols: 3,
          readonly: true,
          field: 'InputMaskField',
          maskFormat: '# ####-####'
        },

        sigla: {
          label: 'Conselho',
          valueField: null,
          cols: 2,
          value: '',
          readonly: true,
          field: 'InputField',
        },

        numeroConselho: {
          label: 'Nº Conselho',
          valueField: null,
          cols: 4,
          value: '',
          readonly: true,
          field: 'InputField',
        },

      },
      filtro: {
        nome: '',
        cpf: '',
        usuario: '',
        perfil: ''
      },
      headers: [
        { text: 'Nome', value: 'nome', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'CPF', value: 'cpf', sortable: false },
        { text: 'Status', value: 'statusDescricao', sortable: false },
        { text: 'Ações', value: 'acoes', sortable: false }
      ],
      pagination: {
        page: 0,
        perPage: 10,
        total: 0
      }
    }
  },

  created() {
    this.definirContexto();
    this.receberColaboradoresVinculados();
  },

  methods: {
    receberColaboradoresVinculados() {
      this.loading = true;
      const paginacao = { page: this.pagination.page, size: this.pagination.perPage };

      colaboradorService
        .colaboradoresVinculadosAFilial(this.getMatrizId(), paginacao)
        .then(({ data }) => {
          this.colaboradores = data.content.map(colaborador => {
            return {
              ...colaborador,
              email: colaborador.email ? this.gerenciarTamanhoItemTabela('email', colaborador.email) : null
            }
          });
          this.pagination.total = data.totalPages
        })
        .catch(() => {})
        .then(() => this.loading = false);
    },

    onUpdateSelect(idColaborador) {
      if (!idColaborador) {
        this.exibirFormularioColaborador = false;
        this.itemSelecionado = '';
        return;
      }

      this.itemSelecionado = idColaborador;
      this.buscarColardoresPorId(idColaborador);
    },

    definirContexto() {
      const href = location.href;

      if (href.includes('/detalhes-filial')) {
        this.contextoComponente = 'area-filial';
        this.rotaDeVolta = '/filiais';
        this.labelContextoDesvinculacao = 'DESTA FILIAL';
      }

      else if (href.includes('/institucional')) {
        this.contextoComponente = 'area-matriz';
        this.labelContextoDesvinculacao = 'DA MATRIZ';
      }
    },

    getMatrizId() {
      if(this.contextoComponente === 'area-matriz') {
        return JSON.parse(sessionStorage.getItem('matriz')).id;
      }

      return this.$route.params.id;
    },

    buscarColardoresNaoVinculadosPorNome(termo){
      if(!termo.length) {
        this.buscarColaboradoresNaoVinculados();
        return;
      }

      colaboradorService
        .colaboradorPorNome(this.getMatrizId(), termo)
        .then(({ data }) => {
          this.colaboradoresNaoVinculados = data;
        });
    },

    buscarColardoresPorId(colaboradorId) {
      if(!colaboradorId) return;

      colaboradorService
        .colaboradorPorId(colaboradorId).then(({ data }) => {
          this.exibirFormularioColaborador = true;
          this.listaEspecializacao = data.listaEspecializacao;
          this.criarFormularioColaborador(data);
        });
    },

    criarFormularioColaborador(dadosColaborador) {
      Object.entries(dadosColaborador).map(([key, value]) => {
        if (this.fields[key]) {
          this.fields[key].valueField = value;
        }
      })
    },

    fecharDialogVincularColaborador() {
      this.dialogVincularColaborador = false;
      this.exibirFormularioColaborador = false;
      this.itemSelecionado = '';
      this.colaboradoresNaoVinculados = [];
      this.listaEspecializacao = [];
      this.paginaAtualSelect = 0;
      this.receberColaboradoresVinculados();
    },

    vincularColaborador() {
      this.dialogVincularColaborador = false;
      this.listaEspecializacao = [];
      this.exibirFormularioColaborador = false;
      this.paginaAtualSelect = 0;


      if (this.itemSelecionado) {
        const body = {
          idEmpresaColaborador: this.getMatrizId(),
          idColaborador: this.itemSelecionado,
        }

        this.loading = true;
        colaboradorService
          .vincularColaboradorAFilial(body)
          .then(() => {
            this.$root.vtoast.show({
              status: 'success',
              text: 'Colaborador vinculado com sucesso.',
              title: 'SUCESSO!',
            })

            this.receberColaboradoresVinculados();
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
          .then(() => this.loading = false);
      }
      this.itemSelecionado = '';
    },

    abrirdialogVincularColaboradorr() {
      this.dialogVincularColaborador = true;
      this.paginaAtualSelect = 0;
      this.buscarColaboradoresNaoVinculados();
    },

    abrirDialogDesvincularColaborador(colaborador){
      this.colaboradorSelecionado = colaborador;
      this.dialogDesvincularColaborador = true;
    },

    fecharDialogDesvincularColaborador() {
      this.dialogDesvincularColaborador = false;
      this.colaboradorSelecionado = null;
      this.receberColaboradoresVinculados();
    },

    desvincularColaborador() {
      const vinculo = this.colaboradorSelecionado.vinculo;
      const body = { status: 3 };

      colaboradorService
        .atualizarStatusColaborador(vinculo, body)
        .then(() => {
          this.$root.vtoast.show({
              status: 'success',
              text: 'Colaborador DESVINCULADO com sucesso.',
              title: 'SUCESSO!',
          });

          this.receberColaboradoresVinculados();
        })
        .catch(erro => {
          this.$root.vtoast.show({
            status: 'error',
            text: erro.response?.data.mensagemUsuario,
            title: erro.response.data.titulo,
          })
        });

      this.colaboradorSelecionado = null;
      this.dialogDesvincularColaborador = false;
    },

    buscarColaboradoresNaoVinculados() {
      const paginacao = { page: 0, size: 10, }
      colaboradorService
        .colaboradoresNaoVinculadosAFilial(this.getMatrizId(), paginacao)
        .then(({ data }) => {
          this.colaboradoresNaoVinculados = data.content;
        })
    },

    gerenciarTamanhoItemTabela(tipoItem, item) {
      if(tipoItem === 'email') {
        return item.length > 70 ? item.trim().slice(0, 70) + '...' : item;
      }
    },

    page(page) {
      this.pagination.page = page - 1;
      this.receberColaboradoresVinculados();
    },

    adicionarMaisColaboraderesNaoVinculados() {
      const paginacao = { page: ++this.paginaAtualSelect, size: 10 };
      colaboradorService
        .colaboradoresNaoVinculadosAFilial(this.getMatrizId(), paginacao)
        .then(({ data }) => {
          this.colaboradoresNaoVinculados = [
            ...this.colaboradoresNaoVinculados,
            ...data.content
          ];
        })
    },
  },
}
</script>

<style scoped>
  .nome { max-width: 300;}

  .selecionado {
    background: #2196f3;
    color: white;
  }

  .selecionado:hover {
    background: #2196f3 !important;
  }

  .celula-tabela.email span {
    width: 100%; overflow-wrap: anywhere;
  }

  .chip-especializacao {
    opacity: 1 !important;
    color: #333;
  }

  .aviso-sem-especializacao { color: #f37b15 }

  .aviso-dialog { padding: 1rem;}
  .aviso-dialog p {
    margin: 0; font-size: 1.2rem;
  }

  .footer-pagina { margin-top: 2rem;}
</style>
