import axios from 'axios'

const API_PRESTADOR = '/api-prestador';

const atribuirParametros = (filtro) => {
  let parametros = [];
  for (const [key, value] of Object.entries(filtro)) {
    if(value || value === 0) parametros.push(`${key}=${value}`);
  }

  if(parametros.length <= 0) return '';
  return parametros.length === 1 ? `?${parametros}` : `?${parametros.join('&')}`;
}

export default {
  cadastrarFilial(body) {
    const url = `${API_PRESTADOR}/empresaColaborador`;
    return axios.post(url, body)
  },

  atualizarFilial(body, filialId) {
    const url = `${API_PRESTADOR}/empresaColaborador/${filialId}/atualizar`;
    return axios.put(url, body);
  },

  filiais(filtro) {
    const parametros = atribuirParametros(filtro);
    const url = `${API_PRESTADOR}/empresaColaborador/listar${parametros}`;
    return axios.get(url);
  },

  filiaisAtivas(paginacao) {
    const parametros = atribuirParametros(paginacao);
    const url = `${API_PRESTADOR}/empresaColaborador/ativos${parametros}`;
    return axios.get(url);
  },

  filialPorId(filialId) {
    const url = `${API_PRESTADOR}/empresaColaborador/${filialId}`;
    return axios.get(url);
  },

  filialPorCNPJ(cnpj) {
    const url = `${API_PRESTADOR}/empresaColaborador/${cnpj}/empresa`;
    return axios.get(url);
  },

  filialPorNome(nome) {
    const url = `${API_PRESTADOR}/empresaColaborador/buscar/${nome}`;
    return axios.get(url);
  },

  filtroFilial(filtro) {
    const parametros = atribuirParametros(filtro);
    const url = `${API_PRESTADOR}/empresaColaborador/filtro${parametros}`;
    return axios.get(url);
  },

  atualizarStatusFilial(filialId, statusId) {
    const url = `${API_PRESTADOR}/empresaColaborador/AtualizarStatus/${filialId}`;
    return axios.put(url, statusId);
  },
}
