<template>
  <div>
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="grey lighten-3"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.content">
          <v-container>
            <component class="mt-5" :is="item.component" />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosFilial from './DadosFilial'
import ColaboradoresFilial from './Colaboradores.vue'

export default {
  components: {
    DadosFilial,
    ColaboradoresFilial,
  },
  data() {
    return {
      tab: null,
      items: [
        {
          tab: 'Dados',
          content: 'tab-dados-filial',
          component: 'DadosFilial'
        },
        {
          tab: 'Colaboradores',
          content: 'tab-colaboradores',
          component: 'ColaboradoresFilial'
        }
      ]
    }
  }
}
</script>
