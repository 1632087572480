<template>
  <div>
    <div class="main">
      <v-row>

        <DialogConfirmation
          tag="edit"
          title="Alterar Dados"
          :show="exibirDialogAvisoEdicao"
          @confirm="alterarDadosFilial()"
          @close="exibirDialogAvisoEdicao = false"
        >
          <template v-slot:body>
            <div class="mt-5">
              Esta ação alterará os dados desta filial. Deseja continuar?
            </div>
          </template>
        </DialogConfirmation>

        <v-col style="margin-top: 4.7rem" md="3" order-md="2">
          <v-avatar size="avatarSize" color="#f1f2f3" rounded>
            <v-img contain height="250" width="250" :src="avatar"></v-img>
          </v-avatar>

          <div v-if="status"
            style="width: 250px"
            class="text-center"
          >
            <StatusChip
              class="mt-1"
              :color="getColor(status)"
            >
              {{ status }}
            </StatusChip
            >
          </div>
        </v-col>

        <v-col offset-sm="12" md="9">
          <TitleH2 class="mt-3 mb-5 mx-3" title="Dados da Filial" />
          <FormField :fields="fields" :validate="validacao"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-end" offset-sm="12" md="9">
          <ButtonMain
            class="primary mr-5 mb-5"
            style="width: 12rem"
            animacao="spin"
            :text="alterandoDadosFilial ?  'Alterando...' : 'Alterar'"
            :icone="alterandoDadosFilial ? 'autorenew' : ''"
            :desabilitar="alterandoDadosFilial"
            @click.native="exibirDialogAvisoEdicao = true"
          ></ButtonMain>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center mt-5" md="12" order-md="1">
          <div class="footer-pagina">
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn class="ml-3" to="/filiais">
                <v-icon small>mdi-arrow-left-thin </v-icon>
                Voltar
              </v-btn>
            </v-toolbar>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import institucionalService from '@/services/InstitucionalService'
import cepService from '@/services/CepService'
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import StatusChip from "@/components/atoms/StatusChip";
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'


export default {
  components: {
    TitleH2,
    FormField,
    StatusChip,
    ButtonMain,
    DialogConfirmation
  },

  data() {
    return {
      avatar: '',
      status: "",
      cepValido: true,
      alterandoDadosFilial: false,
      exibirDialogAvisoEdicao: false,
      criandoFormulario: false,
      validacao: 0,
      dadosFilial: {},
      ufs: [],
      receberEnderecoCep: [],
      fields: {
        nome: {
          label: 'Razão social',
          valueField: null,
          value: '',
          cols: 12,
          field: 'InputField',
          rules: [(value) => !!value || 'Campo obrigatório']
        },
        cnpj: {
          label: 'CNPJ',
          valueField: null,
          cols: 4,
          value: '',
          field: 'InputMaskField',
          maskFormat: '##.###.###/####-##',
        },
        inscricaoMunicipal: {
          label: 'Inscrição Municipal',
          valueField: '',
          cols: 4,
          value: '',
          field: 'InputField'
        },
        inscricaoEstadual: {
          label: 'Inscrição Estadual',
          valueField: '',
          cols: 4,
          value: '',
          field: 'InputField'
        },

        contatosDivisor: {
          cols: 12,
          label: 'Contatos',
          field: 'DividerMain'
        },
        email: {
          label: 'Email',
          valueField: null,
          value: '',
          cols: 7,
          rules: [(value) => !!value || 'Campo obrigatório'],
          field: 'InputField'
        },
        ddd: {
          label: 'DDD',
          valueField: null,
          cols: 2,
          value: '',

          field: 'InputMaskField',
          maskFormat: '##',
          rules: [(value) => !!value || 'Campo obrigatório']
        },
        telefone: {
          label: 'Telefone ',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '#####-####',
          rules: [(value) => !!value || 'Campo obrigatório']
        },
        whatsApp: {
          label: 'Whatsapp',
          valueField: '',
          value: '',
          cols: 4,
          maskFormat: '(##) #####-####',
          field: 'InputMaskField'
        },
        instagran: {
          label: 'Instagram',
          valueField: '',
          value: '',
          cols: 4,
          field: 'InputField'
        },

        enderecoResidencial: {
          cols: 12,
          label: 'Endereço Comercial',
          field: 'DividerMain'
        },
        cep: {
          label: 'CEP',
          valueField: null,
          value: '',
          cols: 3,
          rules: [
            (value) => !!value || 'Campo obrigatório',
            (value) => (this.cepValido && !!value) || 'CEP inválido'
          ],
          field: 'InputMaskField',
          maskFormat: '#####-###'
        },
        logradouro: {
          label: 'Logradouro',
          value: '',
          valueField: null,
          cols: 6,
          rules: [(value) => !!value || 'Campo obrigatório'],
          field: 'InputField'
        },
        numero: {
          label: 'Número',
          valueField: null,
          value: '',
          cols: 3,
          rules: [(value) => !!value || 'Campo obrigatório'],
          field: 'InputField'
        },
        complemento: {
          label: 'Complemento',
          valueField: null,
          value: '',
          cols: 6,

          field: 'InputField'
        },
        bairro: {
          label: 'Bairro',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || 'Campo obrigatório'],
        },
        uf: {
          label: 'UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || 'Campo obrigatório'],
        },
        idCidadeEndereco: {
          label: 'Cidade',
          valueField: null,
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || 'Campo obrigatório'],
        },
        pontoReferencia: {
          label: 'Ponto de Referencia',
          valueField: null,
          cols: 7,
          field: 'InputField',
          value: ''
        },
      },
    }
  },

  created() {
    this.receberUFs();
    this.receberDadosFilial();
  },

  methods: {
    receberDadosFilial() {
      institucionalService
        .filialPorId(this.$route.params.id)
        .then(({ data }) => {
          this.status = data.descricaoStatus;
          this.avatar = `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${data.nome}`;
          this.dadosFilial = data;
          this.criarFormulario(data);
        });
    },

    criarFormulario(data) {
      this.criandoFormulario = true;
      Object.entries(data).map(([key, value]) => {
        if(key === 'cidade') {
          this.fields['idCidadeEndereco'].valueField = Number(value);
        } else if (this.fields[key]) {
          this.fields[key].valueField = value;
        }
      })
    },

    receberUFs() {
      api.getUf().then(({ data }) => {
        this.ufs = data
        this.fields.uf.items = data
      })
    },

    verificarCep(cep) {
      cepService.buscarCep(cep)
        .then(({ data }) => {
          if (data.erro) {
            this.cepValido = false;
            this.fields.bairro.valueField = null;
            this.fields.logradouro.valueField = null;
            this.fields.uf.valueField = null;
            this.fields.uf.value = null;
            this.fields.idCidadeEndereco.valueField = null;
            this.fields.idCidadeEndereco.value = null;
            return;
          }

          this.cepValido = true;
          this.fields.bairro.valueField = data.bairro;
          this.fields.logradouro.valueField = data.logradouro;
          this.receberEnderecoCep = data;
        });
    },

    receberCidade(id) {
      api.getCidadeFromUF(id).then(({ data }) => {
        this.fields.idCidadeEndereco.items = data
      })
    },

    verificarSeHaCampoObrigatorioNulo(campos) {
      let campoInvalido = false;
      for(const [key, value] of Object.entries(campos)) {
        const valorCampo = value.value;
        const regras = value.rules;
        let erroCampo = false;

        if(regras && regras.length) {
          for(const regra of regras) {
            if(regra(valorCampo) === 'CEP inválido' || regra(valorCampo) ===  'Campo obrigatório'
            ) {
              erroCampo = true;
              break;
            }
          }
        }

        if(erroCampo) {
          campoInvalido = true;
          break;
        };
      }

      return campoInvalido;
    },

    alterarDadosFilial() {
      this.exibirDialogAvisoEdicao = false;

      if (this.verificarSeHaCampoObrigatorioNulo(this.fields)) {
        ++this.validacao;
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Dados Incompletos',
        });

        return;
      }

      this.alterandoDadosFilial = true;
      const body = {
        bairro: this.fields.bairro.value,
        cep: this.fields.cep.value,
        cnpj: this.fields.cnpj.value,
        complemento: this.fields.complemento.value,
        ddd: this.fields.ddd.value,
        email: this.fields.email.value,
        cidadeEndereco: Number(this.fields.idCidadeEndereco.value),
        logradouro: this.fields.logradouro.value,
        nome: this.fields.nome.value,
        numero: this.fields.numero.value,
        pontoReferencia: this.fields.pontoReferencia.value,
        telefone: this.fields.telefone.value,
        inscricaoMunicipal: this.fields.inscricaoMunicipal.value,
        inscricaoEstadual: this.fields.inscricaoEstadual.value,
        whatsApp: this.fields.whatsApp.value,
        instagran: this.fields.instagran.value,
        cashback: this.dadosFilial.cashback,
        desconto: this.dadosFilial.desconto,
      }

      institucionalService
        .atualizarFilial(body, this.dadosFilial.id)
        .then(() => {
          this.alterandoDadosFilial = false;
          this.receberDadosFilial();

          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Filial atualizada com sucesso.',
          });
        })
        .catch((err) => {
          this.alterandoDadosFilial = false;

          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    getColor(status) {
      status = status.toUpperCase();
      if (status === "ATIVO") return "green";
      if (status === "INATIVO") return "red";
      if (status === "EM ANALISE") return "orange";
      return "";
    },
  },

  watch: {
    'fields.cep.value': function (cep) {
      if(this.criandoFormulario) {
        this.criandoFormulario = false;
        return;
      }

      if (cep.length >= 8) {
        this.verificarCep(cep)
      }
    },

    'fields.uf.value': function (id) {
      this.receberCidade(id)
    },

    cepValido(valor) {
      if (valor) {
        this.fields.cep.rules = [(value) => !!value || 'Campo obrigatório']
      }
      if (!valor) {
        this.fields.cep.rules = [
          (value) => !!value || 'Campo obrigatório',
          (value) => (this.cepValido && !!value) || 'CEP inválido'
        ]
      }
    },

    receberEnderecoCep: function (valor) {
      this.ufs.filter((uf) => {
        if (uf.sigla === valor.uf) {
          this.fields.uf.valueField = uf.id
          this.fields.uf.value = uf.id
        }
      })

      if (this.cepValido) {
        this.fields.idCidadeEndereco.items.filter((cidade) => {
          if (cidade.nome === valor.localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()) {
            this.fields.idCidadeEndereco.valueField = cidade.id
            this.fields.idCidadeEndereco.value = cidade.id
          }
        })
      }
    }
  },
}
</script>

<style scoped>
  .footer-pagina { margin-top: 2rem;}
</style>
